import Grid from "@mui/material/Grid2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  Link,
  TablePagination,
  Stack,
  Button,
  Box,
} from "@mui/material";
import { useEffect, useState, type ReactNode } from "react";
import { DossierTypeIconRectangle } from "./components/DossierChip";
import { CommuneAutoCompleteWithErrorBoundary } from "components/Inputs";
import { PageLayout } from "components/Layout";
import { LoadingScreen } from "components/Loading";
import type { PaginatedQueryAvis, Avis, PaginatedResult } from "models";
import { TypeDossier } from "models";
import { avisService } from "services";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "config/app-config";
import { withPageErrorBoundary, useSnackbarErrorHandler } from "utils/errorHandling";

const typesDossier = Object.keys(TypeDossier)
  .sort((a, b) => a.localeCompare(b))
  .filter((t) => t !== TypeDossier.PAC);

const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE_SIZE = 10;

function RechercheAvisPage(): ReactNode {
  const navigate = useNavigate();
  const { catchErrors, isLoading } = useSnackbarErrorHandler();
  const [results, setResults] = useState<PaginatedResult<Avis>>({ result: [], totalCount: 0 });
  const [query, setQuery] = useState<PaginatedQueryAvis>({
    offset: DEFAULT_OFFSET,
    size: DEFAULT_PAGE_SIZE,
  });
  const debouncedFetchAvis = useDebouncedCallback(
    async (queryParams: PaginatedQueryAvis): Promise<void> => {
      const result = await avisService.getAllAvis(queryParams);
      setResults(result);
    },
    500
  );
  useEffect(() => {
    void catchErrors(async () => {
      await debouncedFetchAvis(query);
    });
  }, [catchErrors, debouncedFetchAvis, query]);
  const handleFiltreValue =
    (key: keyof PaginatedQueryAvis) =>
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setQuery({ ...query, offset: DEFAULT_OFFSET, [key]: evt.target.value });
    };

  return (
    <PageLayout pageTitle="Recherche d'avis">
      <Grid size="grow" container spacing={2}>
        <Typography component={Grid} size={12} color="primary" variant="body1" fontWeight={600}>
          Filtres
        </Typography>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TextField
            size="small"
            fullWidth
            label="Id"
            name="id"
            value={query.idAvis ?? ""}
            onChange={handleFiltreValue("idAvis")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TextField
            select
            size="small"
            fullWidth
            label="Type de demande"
            name="type"
            value={query.type ?? ""}
            onChange={handleFiltreValue("type")}>
            <MenuItem value="">
              <em>Tous</em>
            </MenuItem>
            {typesDossier.map((key) => (
              <MenuItem key={key} value={key}>
                <Stack component="span" direction="row" spacing={1} alignItems="center">
                  <DossierTypeIconRectangle type={key as TypeDossier} />
                  <Typography sx={{ color: `${key}.dark`, fontWeight: 500 }}>
                    {key.toUpperCase()}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <CommuneAutoCompleteWithErrorBoundary
            size="small"
            communeId={query.codeInseeCommune}
            onChange={(commune) => {
              setQuery({
                ...query,
                codeInseeCommune: commune?.id,
              });
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TextField
            size="small"
            fullWidth
            label="Demandeur"
            name="demandeur"
            value={query.demandeur ?? ""}
            onChange={handleFiltreValue("demandeur")}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <TextField
            select
            size="small"
            fullWidth
            label="Avec dossier ?"
            name="onlyWithDossier"
            value={query.onlyWithDossier ?? ""}
            onChange={handleFiltreValue("onlyWithDossier")}>
            <MenuItem value="">Tous</MenuItem>
            <MenuItem value="true">Oui</MenuItem>
            <MenuItem value="false">Non</MenuItem>
          </TextField>
        </Grid>

        <Grid size={{ xs: 12, md: 6, lg: 2 }}>
          <Button
            onClick={() => {
              setQuery({
                offset: DEFAULT_OFFSET,
                size: DEFAULT_PAGE_SIZE,
                demandeur: "",
                idAvis: "",
              });
            }}
            fullWidth
            sx={{ background: "white" }}
            color="error"
            variant="outlined">
            Réinitialiser les filtres
          </Button>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight={600}>Id</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Type</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Commune</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Demandeur(s)</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Dossier</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.result.map((avis) => (
                <TableRow key={avis.idAvis}>
                  <TableCell>
                    <Typography>{avis.idAvis}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{avis.typeDemande}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{avis.nomCommune}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {avis.demandeur1.nom} {avis.demandeur1.prenom}
                      {avis.demandeur2 != null && (
                        <>
                          / {avis.demandeur2.nom} {avis.demandeur2.prenom}
                        </>
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {avis.idChantier != null && avis.idDossier != null && (
                      <Link
                        component="button"
                        onClick={() => {
                          if (avis.idChantier != null && avis.idDossier != null) {
                            navigate(
                              routesConfig.chantierDossier.getParameterPath(
                                avis.idChantier,
                                avis.idDossier
                              )
                            );
                          }
                        }}>
                        {avis.dossierReference ?? "N/A"}
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={Math.ceil(query.offset / query.size)}
          rowsPerPage={query.size}
          count={results.totalCount}
          onPageChange={(_, pageNum) => {
            setQuery({
              ...query,
              offset: query.size * pageNum,
            });
          }}
          onRowsPerPageChange={(evt) => {
            setQuery({
              ...query,
              offset: DEFAULT_OFFSET,
              size: parseInt(evt.target.value, 10),
            });
          }}
        />
      </Grid>
      {isLoading && (
        <Box sx={{ position: "absolute", left: 0, right: 0, top: 0, pointerEvents: "none" }}>
          <LoadingScreen />
        </Box>
      )}
    </PageLayout>
  );
}

export const RechercheAvisPageWithErrorBoundary = withPageErrorBoundary(RechercheAvisPage);
